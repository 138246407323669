import React from "react";
import ReferMainSection from "../../components/ReferComponents/ReferMainSection";

const Refer = () => {
  return (
    <div className="w-full h-full p-10 flex flex-col gap-4 relative">
        <ReferMainSection/>
    </div>
  );
};

export default Refer;
