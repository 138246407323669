import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MdLocationOn, MdOutlineCardMembership } from "react-icons/md";
import { FaGraduationCap, FaMedal } from "react-icons/fa";
import { GiMiracleMedecine, GiRibbonMedal } from "react-icons/gi";
import { RiMedalLine } from "react-icons/ri";
import { BsJournalMedical } from "react-icons/bs";
import { IoMdPricetag } from "react-icons/io";
import ProfileModal from "./ProfileModal";
// import { GrUserExpert } from "react-icons/gr";

const ProfileMainSection = () => {
  const user = useSelector((state) => state.userReducer.doctor);
  const [open, setOpen] = useState(false);
  const renderSection = (title, data, icon, placeholder) => (
    <div className="mt-1 pt-3 flex flex-wrap border-t">
      <span className="flex w-full font-semibold text-base items-center gap-2">
        {icon}
        {title}
      </span>
      {data?.length > 0 ? (
        data.map((item, idx) => (
          <div
            key={idx}
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className="text-xs mr-2 my-1 uppercase tracking-wider border px-2 py-1 text-white  font-semibold cursor-default rounded-full duration-300"
          >
            {item}
          </div>
        ))
      ) : (
        <span className="flex w-full text-gray-500">{placeholder}</span>
      )}
    </div>
  );

  return (
    <>
      {open && (
        <ProfileModal
          user={user}
          open={open}
          setOpen={setOpen}
          editData={user}
        />
      )}
      <div className="grid md:grid-cols-3 p-5 gap-5 w-full mb-5 ">
        <div className="bg- md:col-span-1 border pb-6 w-full overflow-hidden justify-center items-center h-fit shadow-sm mx-auto">
          <div className="relative h-40 ">
            <img
              className="absolute h-full w-full object-cover"
              alt="cover"
              src={user?.doctor_cover_image}
            />
          </div>
          <div className="relative shadow mx-auto h-24 w-24 -my-12 border-white rounded-full overflow-hidden border-4">
            <img
              className="object-cover w-full h-full"
              alt="profile"
              src={user?.doctor_image}
            />
          </div>
          <div className="mt-16">
            <h1 className="text-lg text-center font-semibold">{user?.name}</h1>
            <p
              className={`${
                user?.experience ? "flex" : "hidden"
              } text-sm text-gray-600 text-center gap-2 items-center justify-center font-bold`}
            >
              <span>{user?.experience} Years</span>
            </p>
            <p
              className={`${
                user?.registration ? "flex" : "hidden"
              } text-sm text-gray-600 text-center gap-2 items-center justify-center font-bold`}
            >
              <span>
                <MdLocationOn />
              </span>
              <span>{user?.registration}</span>
            </p>
            <p
              className={`${
                user?.price ? "flex" : "hidden"
              } text-sm text-gray-600 text-center gap-2 items-center justify-center font-bold`}
            >
              <span>
                <IoMdPricetag />
              </span>
              <span>{user?.price}</span>
            </p>
          </div>
          <div className="pt-3 flex mx-6 justify-center items-center">
            <button
              onClick={() => {
                setOpen(true);
              }}
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className="text-xs mr-2 my-1 uppercase tracking-wider border font-semibold px-2 py-1 text-white   cursor-pointer rounded-full duration-300"
            >
              Edit Profile
            </button>
          </div>
        </div>
        <div className="border md:col-span-2 w-full rounded-sm shadow-sm flex flex-col p-5 gap-3 h-full text-sm">
          <div className="flex w-full items-center justify-start font-bold text-xl">
            General Information
          </div>
          <div className="flex w-full items-center justify-start  flex-col">
            <span className="flex w-full font-semibold text-base items-center gap-2">
              <span>
                <BsJournalMedical />
              </span>
              <span>About</span>
            </span>
            {user?.about_doctor ? (
              <span className="flex w-full font-normal">
                {user.about_doctor}
              </span>
            ) : (
              <span className="flex w-full text-gray-500">
                No about available
              </span>
            )}
          </div>
          <div className="flex w-full items-center justify-start  flex-col">
            <span className="flex w-full font-semibold text-base items-center gap-2">
              <span>
                <MdOutlineCardMembership />
              </span>
              <span>Membership</span>
            </span>
            {user?.membership ? (
              <span className="flex w-full font-normal">{user.membership}</span>
            ) : (
              <span className="flex w-full text-gray-500">
                No membership about available
              </span>
            )}
          </div>
          {renderSection(
            "Top Treatments",
            user?.top_treatments,
            <FaMedal />,
            "No top treatments available"
          )}
          {renderSection(
            "Best Known For",
            user?.doctor_best_known,
            <GiRibbonMedal />,
            "No best knowns available"
          )}
          {renderSection(
            "Expertise",
            user?.doctor_expert,
            <RiMedalLine />,
            "No expertise available"
          )}
          {renderSection(
            "Specialities",
            user?.speciality,
            <GiMiracleMedecine />,
            "No specialities available"
          )}
          {renderSection(
            "Education",
            user?.qualification?.map((item) =>
              item?.year
                ? `${item?.degree}-${item?.year}`
                : `${item?.degree}`
            ),
            <FaGraduationCap />,
            "No education available"
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileMainSection;
