export const validateScheduleForm = ({ dates, isMorning, isAfterNoon, isEvening, morningData, afterNoonData, eveningData },morning_hospital, afternoon_hospital, evening_hospital,setErr) => {
    if (!dates.length) {
        setErr({ global: "Select Dates" });
        return false;
    }
    if (!isMorning && !isAfterNoon && !isEvening) {
        setErr({ global: "Select at least one shift!" });
        return false;
    }
    if (isMorning && (!morningData.startTime || morningData.startTime === "NaN:undefined" || !morningData.endTime || morningData.endTime === "NaN:undefined")) {
        setErr({ global: "Select Start and End time of Morning!" });
        return false;
    }
    if (isMorning && !morning_hospital) {
        setErr({ global: "Select Morning Hospital" });
        return false;
    }
    if (isAfterNoon && (!afterNoonData.startTime || afterNoonData.startTime === "NaN:undefined" || !afterNoonData.endTime || afterNoonData.endTime === "NaN:undefined")) {
        setErr({ global: "Select Start and End time of Afternoon!" });
        return false;
    }
    if (isAfterNoon && !afternoon_hospital) {
        setErr({ global: "Select Afternoon Hospital" });
        return false;
    }
    if (isEvening && (!eveningData.startTime || eveningData.startTime === "NaN:undefined" || !eveningData.endTime || eveningData.endTime === "NaN:undefined")) {
        setErr({ global: "Select Start and End time of Evening!" });
        return false;
    }
    if (isEvening && !evening_hospital) {
        setErr({ global: "Select Evening Hospital" });
        return false;
    }
    return true;
}