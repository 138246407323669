import { axiosAuthorized } from "../../api/config"

export const getRefferals = async (userId) => {
    try {
        const response = await axiosAuthorized.get(`/refferal/doctorrefferal/${userId}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const addRefferal = async (initialState, lab_report, insurance_details, prescription,doctor_id, refferalId = null) => {
    try {
        const requestData = {
            ...initialState,
            lab_report,
            insurance_details,
            prescription,
            doctor_id
        }
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const url = refferalId ? `/refferal/update_doctorrefferal/${refferalId}` : `/refferal/add_doctorrefferal`;
        const response = await axiosAuthorized[refferalId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}