import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import {
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import FileInput from "../Common/FormComponents/FileInput/FileInput";
import ShowFile from "../Common/FormComponents/ShowFile/ShowFile";
import { addRefferal } from "../../utils/referUtils/referUtils";
import { validateReferForm } from "../../utils/referUtils/validateReferForm";

const ReferModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setRefersFn = () => { },
  user
}) => {
  const initialState = {
    patient_name: editData?.name || editData?.patient_name || "",
    email_id: editData?.email || editData?.email_id || "",
    contact_no: editData?.phone_number || editData?.contact_no || "",
    alternative_no: editData?.alternative_no || "",
    region: editData?.region || "",
    disease_name: editData?.disease|| editData?.disease_name || "",
    patient_id: editData?.patient_id || "",
    disease_description: editData?.disease_description || "",
    surgery_cost: editData?.surgery_cost || "",
    medidocs_quotation: editData?.medidocs_quotation || "",
    remarks: editData?.remarks || "",
  };
  const [lab_report, setLabReportAttachment] = useState(
    editData?.lab_report || null
  );
  const [insurance_details, setInsuranceDetailsAttachment] = useState(
    editData?.insurance_details || null
  );
  const [prescription, setPrescription] = useState(
    editData?.prescription || null
  );
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      validateReferForm(formData, lab_report, insurance_details, prescription, setErr)
    ) {
      setErr({ global: "" });
      setLoading(true);
      addRefferal(formData,lab_report,insurance_details, prescription, user?._id ,editData?._id)
        .then((res) => {
          setRefersFn();
          clearForm();
          setEditData(null);
          setOpen(false);
          toast(`Refferal ${editData ? `Edited` : `Added`}`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          toast(`Refferal ${editData ? `Edit` : `Add`} Failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{editData ? "Edit Referral" : "Add New Referral"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"patient_name"}
            name={"Patient Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"email_id"}
            name={"Email Id"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"region"}
            name={"Region"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"contact_no"}
            name={"Contact No"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"alternative_no"}
            name={"Alternative No"}
            hide={true}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"disease_name"}
            name={"Disease Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"patient_id"}
            name={"Patient ID"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            formName={"disease_description"}
            name={"Disease Description"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex flex-col w-full sm:w-1/2 h-fit  gap-5">
          <FileInput fileName={"Lab Report"} setFile={setLabReportAttachment} />
          <ShowFile file={lab_report} setFile={setLabReportAttachment} />
        </div>
        <div className="flex flex-col w-full sm:w-1/2 h-fit  gap-5">
          <FileInput
            fileName={"Insurance Details"}
            setFile={setInsuranceDetailsAttachment}
          />
          <ShowFile
            file={insurance_details}
            setFile={setInsuranceDetailsAttachment}
          />
        </div>
        <div className="flex flex-col w-full sm:w-1/2 h-fit  gap-5">
          <FileInput fileName={"Prescription"} setFile={setPrescription} />
          <ShowFile file={prescription} setFile={setPrescription} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            formName={"surgery_cost"}
            name={"Surgery Cost Apporx"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            formName={"remarks"}
            hide={true}
            name={"Remarks"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferModal;
