function convertTimeStringToMinutes(timeString) {
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':').map(Number);
    const adjustedHours = period === 'PM' && hours !== 12 ? hours + 12 : hours;
    return adjustedHours * 60 + minutes;
}

function convertMinutesToTimeString(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
    return `${adjustedHours}:${mins < 10 ? '0' : ''}${mins} ${period}`;
}

const generateTimeSlots = (start, end, gap) => {
    const timeSlots = [];
    let currentTime = convertTimeStringToMinutes(start);
    const endTime = convertTimeStringToMinutes(end);

    while (currentTime <= endTime) {
        const formattedTime = convertMinutesToTimeString(currentTime);
        timeSlots.push({ time: formattedTime, isBooked: false });
        currentTime += gap;
    }

    return timeSlots;
};

export const generateSchedule = (date, isMorning, morningStart, morningEnd, isAfternoon, afternoonStart, afternoonEnd, isEvening, eveningStart, eveningEnd, gap=15) => {
    const schedule = { date };

    if (isMorning) {
        schedule.morning = generateTimeSlots(morningStart, morningEnd, gap);
    }

    if (isAfternoon) {
        schedule.afternoon = generateTimeSlots(afternoonStart, afternoonEnd, gap);
    }

    if (isEvening) {
        schedule.evening = generateTimeSlots(eveningStart, eveningEnd, gap);
    }

    return schedule;
};