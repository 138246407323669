import { axiosAuthorized } from "../../api/config"
import { store } from "../../redux/store";
import { saveUser } from "../../redux/userSlice";

export const getDoctors = async (mode = "Requested", userId) => {
  try {
    let url = `/doctor/unverifydoctor/${userId}`;
    if (mode === 'Approved') {
      url = `/doctor/verify_doctor/${userId}`;
    }
    const response = await axiosAuthorized.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addDoctor = async (initialState, department_id, degreeData, specialities, profileImage, coverImage, top_treatments, doctor_best_known, doctor_expert, doctor_video, doctor_experience, hospital_id, doctorId) => {
  try {
    department_id = department_id?.map((item) => item?._id);
    const data = {
      name: initialState.name,
      membership: initialState.membership,
      price: initialState.price,
      age: initialState.age,
      gender: initialState.gender,
      department_id,
      doctor_video,
      doctor_experience,
      registration: initialState.registration,
      doctor_best_known,
      doctor_expert,
      top_treatments,
      experience: initialState.experience,
      about_doctor: initialState.about_doctor,
      qualification: degreeData,
      speciality: specialities,
      email: initialState.email,
      phone_number: initialState.phone,
      meta_name: initialState.meta_name,
      meta_description: initialState.meta_description,
      hospital_id,
    };
    const requestData = doctorId ? data : {
      ...data,
      doctor_image: profileImage,
      doctor_cover_image: coverImage,
    };
    const requestConfig = {
      headers: { "Content-Type": doctorId ? "application/json" : "multipart/form-data" },
    };

    const url = doctorId
      ? `/doctor/update/${doctorId}`
      : `/doctor/add_doctor`;
    const mainRequest = axiosAuthorized[doctorId ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (doctorId && profileImage instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/doctor/profile_update/${doctorId}`, { doctor_image: profileImage, }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (doctorId && coverImage instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/doctor/update_doctor/${doctorId}`, { doctor_cover_image: coverImage }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    const user = JSON.stringify(response.data.data);
    localStorage.setItem('doctor', user);
    store.dispatch(saveUser(response.data.data));
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteDoctor = async (doctorId) => {
  try {
    const response = await axiosAuthorized.delete(`/doctor/delete_doctor/${doctorId}`);
    return response;
  } catch (error) {
    throw error;
  }
}