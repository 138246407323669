import React, { useMemo } from 'react'
import { Table } from '../../Common';
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteFollowupAppointment } from '../../../utils/appointmentsUtils/appointmentsUtils';

const MyPatientsFollowupTable = ({ datas, setFollowupFn }) => {
    const headings = useMemo(
      () => [ "Name","P-ID","V-ID","Reason","Date","Time","Delete"],
      []
  );
  const delFollowup = (followUpId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteFollowupAppointment(followUpId).then(res => setFollowupFn()).catch(err => console.log(err));
  }
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.patient_id?.name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              {data?.patient_id?.patient_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.visit_id}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap truncate">
            <div className="text-sm text-gray-900 capitalize truncate">
              {data?.reasons}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.date}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.time}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delFollowup(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default MyPatientsFollowupTable