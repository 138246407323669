import React, { useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../../hooks';
import { DateInput, TextAreaInput, TimeInput } from '../../Common/FormComponents';
import { validateFollowupForm } from '../../../utils/myPatientsUtils/validateTestForm';
import { addFollowupAppointment } from '../../../utils/appointmentsUtils/appointmentsUtils';

const MyPatientsFollowupModal = ({ followupData = null, setFollowup, user }) => {
    const myPatientsFollowupInitialState = {
      patient_id: followupData?.patient_id?._id || "",
      reasons: "",
      date: "",
      time: "",
    };
    const [myPatientsFollowupData, handleMyPatientsFollowupForm] = useForm(myPatientsFollowupInitialState);
    const [loading,setLoading] = useState(false);
    const [err, setErr] = useState({ global: "" });
    const handleSubmit = (e) => {
      e.preventDefault();
      if (validateFollowupForm(myPatientsFollowupData, setErr)) {
        setErr({ global: "" });
        setLoading(true);
        addFollowupAppointment(myPatientsFollowupData,user?._id)
          .then(res => { setFollowup(null) })
          .catch(err => console.log(err))
          .finally(() => setLoading(false));
      }
    };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Add Follow Up"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setFollowup(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <DateInput
            name={"Date"}
            formName={"date"}
            data={myPatientsFollowupData}
            handleInput={handleMyPatientsFollowupForm}
            setError={setErr}
          />
          <TimeInput
            name={"Time"}
            formName={"time"}
            data={myPatientsFollowupData}
            handleInput={handleMyPatientsFollowupForm}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput
            name={"Reasons"}
            formName={"reasons"}
            data={myPatientsFollowupData}
            handleInput={handleMyPatientsFollowupForm}
            setError={setErr}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setFollowup(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPatientsFollowupModal