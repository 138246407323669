import React from "react";
import { useMemo } from "react";
import Table from "../Common/Table/Table";

const ReferTable = ({ datas, setEditData, setOpen }) => {
  const headings = useMemo(
    () => [
      "P-Name",
      "P-ID",
      "Contact",
      "Region",
      "P-Details",
      "R-Status",
    ],
    []
  );
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.patient_name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.patient_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.contact_no}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.region}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-white text-center bg-primary rounded-md capitalize p-1 cursor-pointer "
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              Patient Details
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className={`text-sm text-center capitalize px-2 py-1 rounded-full text-white ${
                data?.refferal_status === "Rejected"
                  ? "bg-red-500"
                  : data?.refferal_status === "Pending"
                  ? "bg-blue-500"
                  : "bg-green-500"
              }`}
            >
              {data?.refferal_status}
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default ReferTable;
