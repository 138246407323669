import "./layout.css";
import React from "react";
import { Outlet } from "react-router-dom";
import { NavBar } from "../components/navbar/navabar";

export const Layout = () => {
  return (
    <div className="flex flex-col">
      <div className="w-full">
        <NavBar />
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

