export const validateReferForm = (initialState, lab_report, insurence_details, prescription, setErr) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!initialState.patient_name.trim().length) {
        setErr({ global: "Invalid Patient Name" });
        return false;
    }
    if (!initialState.email_id.trim().length || !emailRegex.test(initialState.email_id)) {
        setErr({ global: "Invalid Email Address" });
        return false;
    }
    if (!initialState.region.trim().length) {
        setErr({ global: "Invalid Region" });
        return false;
    }
    if (!initialState.contact_no.trim().length) {
        setErr({ global: "Invalid Contact No" });
        return false;
    }
    if (!initialState.disease_name.trim().length) {
        setErr({ global: "Invalid Disease Name" });
        return false;
    }
    if (!initialState.patient_id.trim().length) {
        setErr({ global: "Invalid Patient Id" });
        return false;
    }
    if (!initialState.disease_description.trim().length) {
        setErr({ global: "Invalid Disease Description" });
        return false;
    }
    if (!initialState.surgery_cost.trim().length) {
        setErr({ global: "Invalid Doctor Cost" });
        return false;
    }
    return true;
}