import React from "react";
import "./SearchInput.css";
const SearchInput = ({placeholder,searchQuery,setSearchQuery}) => {
    return (
      <div className="flex w-full">
        <input
          type="text"
          placeholder={placeholder}
          className="search-input"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        <button className="bg-primary text-white py-2 px-4 rounded-e-full">Search</button>
      </div>
    );
};

export default SearchInput;
